import React, { useState } from "react";
import { Tabs, Row, Col, Input, Button, Tag, Table, notification } from "antd";
import { ArrowRightOutlined, DownloadOutlined } from "@ant-design/icons";
import $axios from "../services/axios";
import * as XLSX from "xlsx";

const { TabPane } = Tabs;

const YourComponent = () => {
  const [activeTab, setActiveTab] = useState("uid");
  const [uid, setUid] = useState("");
  const [phone, setPhone] = useState("");
  const [listDataPhone, setListDataPhone] = useState([]);
  const [listDataUid, setListDataUid] = useState([]);

  const [loadingUid, setLoadingUid] = useState(false);
  const [loadingPhone, setLoadingPhone] = useState(false);

  const convertToPhone = async () => {
    setLoadingUid(true);

    if (listUid.length === 0) {
      setLoadingUid(false);
      notification.error({
        message: "Có lỗi xảy ra",
        description: "Bạn phải nhập ít nhất 1 UID",
      });
      return;
    }
    for (let uid of listUid) {
      try {
        const response = await $axios.$get(`/phone/get-phone-by-uid?uid=${uid}`);
        setListDataUid((prev) => [
          ...prev,
          { uid: response.data.uid, phones: response.data.phones, key: uid },
        ]);

        notification.success({
          message: "Hoàn thành",
          description: "Đã convert xong danh sách UID sang số điện thoại",
        });
      } catch (error) {
        notification.error({
          message: "Thất bại",
          description: "Có lỗi xảy ra",
        });
      }
    }
    setLoadingUid(false);
    setUid("");

    return;
  };

  const convertToUid = async () => {
    setLoadingPhone(true);

    if (listPhone.length === 0) {
      setLoadingPhone(false);
      notification.error({
        message: "Có lỗi xảy ra",
        description: "Bạn phải nhập ít nhất 1 số điện thoại ",
      });
      return;
    }

    for (let phone of listPhone) {
      try {
        const response = await $axios.$get(
          `/phone/get-uid-by-phone?phone=${phone}`
        );

        setListDataPhone((prev) => [
          ...prev,
          { uids: response.data.uids, phone: response.data.phone, key: phone },
        ]);

        notification.success({
          message: "Hoàn thành",
          description: "Đã convert xong danh sách số điện thoại sang UID",
        });
      } catch (error) {
        notification.error({
          message: "Thất bại",
          description: "Có lỗi xảy ra",
        });
      }
    }

    setLoadingPhone(false);
    setPhone("");

    return;
  };

  const listUid = uid
    .split("\n")
    .map((e) => e.trim())
    .filter((e) => e.length > 0);

  const listPhone = phone
    .split("\n")
    .map((e) => e.trim())
    .filter((e) => e.length > 0);

  const exportToExcel1 = () => {
    const data = listDataPhone.map((item) => ({
      Phone: item.phone,
      UID: item.uids.map(uid => {
        return uid.uid;
      }).join(', ')
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Export.xlsx");
  };

  const exportToExcel2 = () => {
    const data = listDataUid.map((item) => ({
      UID: item.uid,
      Phone: item.phones.map(phone => {
        return `${phone.phone} ${phone.name ? `(${phone.name})` : ``}`
      }).join(', ')
    }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Export.xlsx");
  };
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      notification.success({
        message: "Hoàn thành",
        description: "Copy thành công",
      });
    }).catch(err => { });
  };

  return (
    <div className="card">
      <Tabs
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key)}
        type="card"
      >
        <TabPane className="px-3 pb-3" tab="Chuyển đổi UID sang SĐT" key="uid">
          <Row gutter={20}>
            <Col span={24} offset={0} align="left">
              <Input.TextArea
                rows={6}
                placeholder="Nhập danh sách UID, mỗi UID 1 dòng..."
                value={uid}
                onChange={(e) => setUid(e.target.value)}
              />
            </Col>
          </Row>
          <br />
          <Row gutter={20}>
            <Col span={24} offset={0} align="left">
              <Button
                type="primary"
                className="button button--primary"
                icon={<ArrowRightOutlined />}
                loading={loadingUid}
                style={{ fontSize: "12px" }}
                onClick={convertToPhone}
              >
                Lấy số điện thoại
              </Button>

              <Button
                type="primary"
                className="button button--primary mx-2"
                style={{ fontSize: "12px" }}
                icon={<DownloadOutlined />}
                onClick={exportToExcel2}
              >
                Xuất file Excel
              </Button>
            </Col>
          </Row>
          <br />
          <Row gutter={20}>
            <Col span={24} offset={0} align="right">
              <Tag
                closable={false}
                className="text-14 border-primary text-primary bg-white py-1"
                effect="plain"
              >
                {listDataUid.length} UID
              </Tag>
              <Tag
                closable={false}
                className="text-14 border-primary text-primary bg-white py-1"
                effect="plain"
              >
                {listDataUid.filter(item => item.phones.length).length} Phone
              </Tag>
            </Col>
          </Row>
          <br />
          <Table
            dataSource={listDataUid}
            pagination={false}
            scroll={{
              x: "max-content",
              y: "40vh",
            }}
            loading={setLoadingUid}
            bordered
            columns={[
              { title: "UID", dataIndex: "uid", align: "center" },
              {
                title: "Số điện thoại",
                dataIndex: "phone",
                align: "center",
                render: (text, record) => (
                  <>
                    {record?.phones?.length ? (
                      <>
                        {record.phones.map((data, index) => {
                          return (
                            <>
                              <Button
                                className={`${data.name
                                  ? "bg-blue text-white border-blue"
                                  : "text-white bg-danger border-danger"
                                  } text-white text-12`}
                                size="small"
                                onClick={() => copyToClipboard(data.phone)}
                              >
                                {data}
                              </Button>
                              <br />
                            </>
                          );
                        })}
                      </>
                    ) : null}
                  </>
                ),
              },
            ]}
          />
        </TabPane>
        <TabPane
          className="px-3 pb-3"
          tab="Chuyển đổi SĐT sang UID"
          key="phone"
        >
          <Row gutter={20}>
            <Col span={24} offset={0} align="left">
              <Input.TextArea
                rows={6}
                placeholder="Nhập danh sách SĐT, mỗi SĐT 1 dòng..."
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Col>
          </Row>
          <br />
          <Row gutter={20}>
            <Col span={24} offset={0} align="left">
              <Button
                type="primary"
                className="button button--primary"
                icon={<ArrowRightOutlined />}
                style={{ fontSize: "12px" }}
                loading={loadingPhone}
                onClick={convertToUid}
              >
                Lấy danh sách UID
              </Button>

              <Button
                type="primary"
                className="button button--primary mx-2"
                style={{ fontSize: "12px" }}
                icon={<DownloadOutlined />}
                onClick={exportToExcel1}
              >
                Xuất file Excel
              </Button>
            </Col>
          </Row>
          <br />
          <Row gutter={20}>
            <Col span={24} offset={0} align="right">
              <Tag
                className="text-14 border-primary text-primary bg-white py-1"
                closable={false}
                effect="plain"
              >
                {listDataPhone.length} Phone
              </Tag>
              <Tag
                className="text-14 border-primary text-primary bg-white py-1"
                closable={false}
                effect="plain"
              >
                {listDataPhone.filter(item => item.uids.length).length} UID
              </Tag>
            </Col>
          </Row>
          <br />
          <Table
            dataSource={listDataPhone}
            pagination={false}
            scroll={{
              x: "max-content",
              y: `calc(100vh - 300px)`,
            }}
            loading={setLoadingPhone}
            bordered
            columns={[
              {
                title: "UID",
                dataIndex: "uid",
                align: "center",
                render: (text, record) => (
                  <>
                    {record?.uids?.length ? (
                      <>
                        {record.uids.map((data, index) => {
                          return (
                            <>
                              <span>{data.uid}</span>
                              <br />
                            </>
                          );
                        })}
                      </>
                    ) : null}
                  </>
                ),
              },
              { title: "Phone", dataIndex: "phone", align: "center" },
            ]}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default YourComponent;
